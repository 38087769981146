import React from "react";
import "../style/productAd.css";
import logo from "../assets/logo.webp";
import sock from "../assets/sock.webp";
import spec7 from "../assets/s7.avif";
import spec8 from "../assets/s8.avif";
import spec9 from "../assets/s9.avif";
import spec10 from "../assets/s10.avif";
import spec11 from "../assets/s11.avif";
import reviews from "../assets/reviews.png";
import ReviewSlider from "./ReviewSlider";
import ReactGA from 'react-ga4';
import shoeGif from '../assets/classic.gif';
import LazyImage from "./LazyLoadImage";

function ProductAd({adLocation}) {

  const handleClick = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Custom Button Click',
      label: adLocation
    });
  };  

  return (
    <article>
      <div className="articleBox">

        {/* <img src={logo} alt="Bare Footwear Logo"></img> */}
        <LazyImage src={logo} alt='Bare Footwear Logo' />
        <h2>Recommended:</h2>
{/* 
        <img src={reviews} alt='rating'></img> */}
        <LazyImage src={reviews} alt='rating' />
        
        {/* <img src={shoeGif} alt="Bare socks 2.0"></img> */}
        <div id="gifWrapper">
        <LazyImage src={shoeGif} alt='Bare socks 2.0' />
        </div>

        <div id="productSpecs">
          <div className="productSpec">
            {/* <img src={spec7} alt=""></img> */}
            <LazyImage src={spec7} alt='Wide Toe Box' />
            <div className="specContent">
                <span>WIDE TOE BOX</span>
                <span className="specLightText">For toes to spread naturally</span>
            </div>
          </div>
          <div className="productSpec">
            {/* <img src={spec8} alt=""></img> */}
            <LazyImage src={spec8} alt='Light-Weight' />
            <div className="specContent">
            <span>LIGHT-WEIGHT</span>
                <span className="specLightText">240 grams per shoe</span>
            </div>
          </div>
          <div className="productSpec">
            {/* <img src={spec9} alt=""></img> */}
            <LazyImage src={spec9} alt='Vegan' />
            <div className="specContent">
            <span>VEGAN</span>
                <span className="specLightText">Planet-friendly</span>
            </div>
          </div>
          <div className="productSpec">
            {/* <img src={spec10} alt=""></img> */}
            <LazyImage src={spec10} alt='Zero-Drop' />
            <div className="specContent">
            <span>ZERO-DROP</span>
                <span className="specLightText">Improving a balanced posture</span>
            </div>
          </div>
          <div className="productSpec">
            {/* <img src={spec11} alt=""></img> */}
            <LazyImage src={spec11} alt='Flexible' />
            <div className="specContent">
            <span>FLEXIBLE</span>
                <span className="specLightText">A grounded experience</span>
            </div>
          </div>
        </div>

        <a href="https://barefootwear.com/products/bareshoes-classic-knit-white" target="_blank" rel="noreferrer">
        <button onClick={() => handleClick()} className="btn-shop">Check Availability</button>
        </a>
        {/* <span className="reviewSpan specLightText">Read Customer Reviews</span>
        <ReviewSlider /> */}

      </div>
    </article>
  );
}

export default ProductAd;
